import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F5F3F0',
      neutralDark4: '#646464',
      neutralDark3: '#453B3B',
      neutralDark2: '#2F2D2D',
      neutralDark1: '#000000',
      primaryDark2: '#7B0222',
      primaryDark1: '#57081D',
      primaryLight: '#A3012C',
      dangerLight: '#A3012C',
    },
  },
  fontFamily: {
    heading: "'STIX Two Text', serif",
    paragraph: "'Rubik', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
